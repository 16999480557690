.search-tools-view .tools-btn-box{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.search-tools-view .tools-btn-box button{
    height: 40px;
    border-radius: 20px;
    padding: 0 20px;
    margin-left: 15px;
}
.search-tools-view .tools-btn-box button:first-child{
    margin-left: 0px;
}

body .search-tools-view .ant-form-item-control{
    margin: 0;
}

.search-tools-view{
    padding: 20px;
    padding-top: 0;
    background-color: #fff;
    border-radius: 15px;
    /* margin-top: 20px; */
    padding-bottom: 10px;
}


.search-tools-view .ant-form-inline .ant-form-item{
    margin: 0;
}


.search-tools-view .tools-from-box{
    flex: 1;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.search-tools-view .tools-from-box .ant-form-item{
    margin-right: 25px;
    margin-bottom: 10px;
}