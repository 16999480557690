.wenzhang-edit-add-view{
    max-width: 1200px;
    padding-top: 15px;
}


.wenzhang-edit-add-view .sub-modal-btn-box{
    display: flex;
    flex-direction: row;
}

.wenzhang-edit-add-view .sub-modal-btn-box button:last-child{
    margin-left: 10px;
}
.wenzhang-edit-add-view .sub-modal-btn-box .ant-form-item-control-input-content{
    justify-content: flex-start;
}


.wenzhang-edit-add-view .bf-container{
    border: 1px solid #000;
    border-radius: 15px;
}  