#loginContainer {
    background-color: #f0f2f5;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  
    // background-image: url(../../assets/loginbg.svg);
    // background-repeat: no-repeat;
    // background-position: center 110px;
    // background-size: 100%;

    background: linear-gradient(to bottom, #CEEBF8 0%, #FFFFFF 90%);
  
    .loginTop {
      width: 328px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: center;
      span {
        margin-right: 30px;
      }
    }
    form {
      width: 328px;
      
    }

.loginFormButton{
    margin-bottom: 20px;
}
@keyframes rollout {
  0% { height: 0;opacity: 0; }
  100% { height: 64px;opacity: 1; }
}

.captchaView{
  animation: rollout 0.5s;
  button{
    border: 0;
    padding: 0;
    background-color: #fff;
    opacity: 1;
    font-size: 13px;
    display: block;
  }
}
    
  }

 