.menu-logo {
    height: 32px;
    padding: 16px;
    background: #001529;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.menu-logo > span{
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: block;
    flex-shrink: 0;
    width: 100%;
    text-align: center;
}

.ant-layout-header .trigger{
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}


.ant-layout-sider,
.ant-layout{
  height: 100%;
}

.ant-layout-header{
  position: sticky;
    top: 0;
}
.ant-layout{
  overflow-y: auto;
}


.left-menus-sider{
    
}


.ant-layout-header{
    display: flex;
    justify-content: space-between;
}

.header-tools{
    display: flex;
    align-items: center;
}

.header-tools .login-out-div{
    display: flex;
    align-items: center;

} 
.header-tools .login-out-div .login-out-icon{
    height: 30px;
    width: 30px;
    margin-right: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

}

.header-tools .user-info{
    display: flex;
    align-items: center;
    margin-right: 45px;
    height: 30px;
    padding: 4px 6px;
    box-sizing: content-box;
    border-radius: 4px;
}

.header-tools .user-head{
    width: 28px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin-right: 8px;
}

.header-tools .user-name{
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
}