.menus-styles{
    height: calc(100% - 64px);
    overflow-y: auto;
    padding-bottom: 100px;
}


.menus-styles::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
  }
  
  .menus-styles::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #C1C1C1;
  }
  
  .menus-styles::-webkit-scrollbar-thumb:hover{
    background-color: #7D7D7D;
  }
  
  .menus-styles::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); */
    border-radius: 6px;
    background-color: #fff;
  }