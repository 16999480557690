body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100vh;
}
.hover-color-bg{
  cursor: pointer;
}
.hover-color-bg:hover{
  background-color: rgba(150,150,150,0.1);
  transition: background-color 0.3s;
}

button a{
  outline: none;
  color: #fff;
}

.ant-wave{
  display: none;
}
/* <div><div class="ant-wave css-dev-only-do-not-override-k83k30 wave-motion-appear wave-motion-appear-active wave-motion" style="left: 715.156px; top: 112px; width: 73.8438px; height: 40px; border-radius: 19.9578px; --wave-scale: 0.9978885135135135; --wave-color: rgb(27, 108, 229);"></div></div> */



/* .ant-image-preview-mask{
  z-index: 10000!important;
}

.ant-image-preview-wrap{
  z-index: 10080!important;
} */


.ant-table-even-row {
  background-color: #ffffff;
}

.ant-table-odd-row {
  background-color: #fcfcfc;
}



.line-clamp-ellipsis-3{
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
   /* autoprefixer: off */
-webkit-box-orient: vertical;  
/* autoprefixer: on */
}

.line-clamp-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  word-wrap: break-word;
}




