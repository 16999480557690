.drg-modal .ant-modal-content{
    padding: 0;
    padding-bottom: 20px;
}

.drg-modal .ant-modal-header .ant-modal-title > div{
    padding: 20px 24px;
    padding-bottom: 0;
}

.my-dragg-modal-content{
    padding: 20px 24px;
    padding-top: 0;
    padding-bottom: 0;
}

.drg-modal .ant-modal-footer{
    padding: 0 24px;
}

